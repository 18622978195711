.GeeksForGeeks {
  background-image: url('./assets/Dolphin-Hero-Image.svg');
  background-size: 'cover';
  background-repeat: no-repeat;
}


.hero{
  background-image: url('./assets/Hero-Image.png');
  background-size: 'cover';
  background-repeat: no-repeat;
}
